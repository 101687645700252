import React, { Component } from 'react'

import Button from '../../components/button'
import Card from '../../components/card'
import ComplexTable from '../../components/ComplexTable';
import DashboardLayout from '../../components/dashboard/DashboardLayout';
import DateRange from '../../components/date/range'
import FilterPane from '../../components/filter/pane'
import FilterPanel from '../../components/filter/panel'
import Icon from '../../components/icon'
import LocationsFilter from '../../components/filter/locations'
import Page from '../../components/page';
import PageTitle from '../../components/pageTitle'
import SelectGroup from '../../components/selectGroup'

// Mock data
import { defaultReportFields } from '../../lib/defaults'
import { data } from '../../store/mock-reports'

const flatFields = defaultReportFields.reduce((acc, cur) => (
  [...acc, ...cur.options]
), [])

class ResearchReport extends Component {

  constructor() {
    super()
    this.state = {
      filter: {
        from: undefined,
        to: undefined,
        locations: []
      },
      fields: {
        appointment: ['FOLDNO', 'LAB']
      }
    }
  }

  setDates = date => {
    const {filter} = this.state

    this.setState({filter: Object.assign(filter, date)})
  }

  toggleChecked = event => {
    let locations = [...this.state.filter.locations]
    const index = locations.indexOf(event.target.value)

    if (index === -1) {
      locations.push(event.target.value)
    }
    else {
      locations.splice(index, 1)
    }

    const filter = Object.assign({}, this.state.filter, {locations})
    this.setState({ filter });
  }

  toggleCheckedField = (event, fieldGroup) => {
    const { fields } = this.state
    const section = fields[fieldGroup] ? [...fields[fieldGroup]] : []

    const index = section.indexOf(event.target.value)

    if (index === -1) {
      section.push(event.target.value)
    }
    else {
      section.splice(index, 1)
    }

    this.setState({ fields: Object.assign(fields, { [fieldGroup]: section }) })
  }

  toggleAll = (values) => {
    const locations = values;
    const filter = Object.assign({}, this.state.filter, { locations })
    this.setState({ filter });
  }

  toggleAllFields = fieldGroup => {
    const { fields } = this.state
    let section = fields[fieldGroup] ? [...fields[fieldGroup]] : []

    const defaultFields = [...defaultReportFields.find(fields => fields.id === fieldGroup).options]

    if (section.length === defaultFields.length) {
      section = []
    }
    else {
      section = defaultFields.map(item => item.id)
    }

    this.setState({ fields: Object.assign(fields, { [fieldGroup]: section }) })
  }

  render() {
    const { locations, from, to } = this.state.filter;
    const { fields } = this.state;

    const selectedFields = Object.keys(fields).reduce((acc, cur) => (
      [...acc, ...fields[cur]]
    ), [])

    const columns = selectedFields.map(id => ({
      Header: flatFields.find(field => field.id === id).label,
      accessor: id,
      sortable: false,
      minWidth: 150,
      Cell: props => <span className="row-report">{props.value}</span>
    }))

    return (
    <Page>
      <DashboardLayout>
        <div className="container">
          <div className="container--main">
            <PageTitle title="Research">
              <Button theme="primary">
                <Icon theme="primary" icon="download" />
                Export Results
              </Button>
            </PageTitle>
          </div>
          <aside className="container--aside container--right">
            <Button>Save</Button>
          </aside>
        </div>
        <div className="container">
          <div className="container--main">
            <Card>
              <ComplexTable
                data={data}
                columns={columns}
                showPagination={false}
              />
            </Card>
          </div>
          <aside className="container--aside">
            <FilterPanel title="Filter Results">
              <FilterPane title="Date">
                <DateRange from={from} to={to} onChange={this.setDates} />
              </FilterPane>
              <LocationsFilter values={locations} onChange={this.toggleChecked} all={this.toggleAll} />
            </FilterPanel>
            <FilterPanel title="Selected">
              {defaultReportFields.map(defaultFields => (
                <FilterPane key={defaultFields.id} title={defaultFields.label}>
                  <SelectGroup
                    id={defaultFields.id}
                    options={defaultFields.options}
                    values={fields[defaultFields.id] ? fields[defaultFields.id] : []}
                    onChange={() => this.toggleCheckedField(event, defaultFields.id)}
                    all={() => this.toggleAllFields(defaultFields.id)}
                  />
                </FilterPane>
              ))}
            </FilterPanel>
          </aside>
        </div>
      </DashboardLayout>
    </Page>
    )
  }

}

export default ResearchReport
